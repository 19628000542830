<template>
  <div class="hello">
    <router-link tag="div" to="/"><i class="el-icon-back"></i>go to home</router-link>
  </div>
</template>

<script>
export default {
name: "404"
}
</script>

<style scoped>
.hello{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: start;
  background: url("../../../public/img/404444.jpg") no-repeat center;
  background-size: cover;
}
.hello div{
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
  /*background-color: #023972;*/
  margin-top: 10px;
}
.hello div:hover{
  opacity: .7;
}
.el-icon-back{
  margin-right: 5px;
  margin-top: 5px;
}
</style>